.disclaimer {
  display: block;
  text-align: center;
}

.disclaimer__text {
  width: 60%;
  padding: 1em 0;
  display: inline-block;
}

@media (max-width: 75em) {
  .disclaimer__text {
    width: 65%;
  }
}

@media (max-width: 68.75em) {
  .disclaimer__text {
    width: 75%;
  }
}

@media (max-width: 60.625em) {
  .disclaimer__text {
    width: 80%;
  }
}

@media (max-width: 37.5em) {
  .disclaimer__text {
    width: 90%;
  }
}
