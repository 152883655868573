.title {
  font-size: 3em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 1.5em 0;
}

@media screen and (max-width: 37.5em) {
  .title {
    font-size: 2.3em;
  }
}
