.rates__result {
  font-size: 2.5rem;
  padding: 0.6em 0;
}

.rates__result--source {
  color: #e2492f;
}

.rates__result--treaty {
  color: #88b04b;
}

.rates__hidden {
  opacity: 0;
}

.rates__show {
  opacity: 1;
}
