.country-select {
  width: 25rem;
  margin: 0 1em;
}

@media (max-width: 48.75em) {
  .country-select {
    width: 75%;
  }

  .country-select:not(:last-child) {
    margin-bottom: 2em;
  }
}

@media (max-width: 37.5em) {
  .country-select {
    width: 90%;
  }
}
