.container {
  margin: 0 auto;
  text-align: center;
  max-width: 1400px;
}

.containter__results {
  position: relative;
  margin: 2.5em 0;
}
